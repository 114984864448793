<template>
  <div id="data-list-thumb-view ">
    <vs-row vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="12">
      <v-select
        class="searchAndSort sm:mr-2 xs:mr-1"
        v-model="selectedStatus"
        @input="getOrdersWithStatus()"
        placeholder="All"
        :options="statusList"
      >
      </v-select>
      <vs-input
        class="searchAndSort sm:ml-2 pt-2 pb-2 w-full"
        icon="search"
        icon-no-border
        size="medium"
        v-model="searchValue"
        @keypress.enter="searchOrder()"
      />
    </vs-row>
    <vs-table :data="orders">
      <template slot="thead">
        <vs-th>{{ $t("No_Number") }}</vs-th>
        <vs-th class="date">{{ $t("Date") }}</vs-th>
        <vs-th class="resinGr"
          ><p class="resinGr">{{ $t("Resin") }} {{ $t("Gr") }}</p></vs-th
        >
        <vs-th class="rowWeight">
          <div class="vx-row">
            <div class="text-center">
              <p class="approxGoldWeight">{{ $t("Approx_Gold_Weight") }}</p>
              <vs-th>
                <p class="goldWeight">14 K</p>
              </vs-th>
              <vs-th>
                <p class="goldWeight">18 K</p>
              </vs-th>
              <vs-th>
                <p class="goldWeight">21 K</p>
              </vs-th>
            </div>
          </div>
        </vs-th>
        <vs-th>{{ $t("Credit") }}</vs-th>
        <vs-th>{{ $t("Status") }}</vs-th>
        <vs-th class="avatars">{{ $t("Images") }}</vs-th>
        <vs-th></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="order" :key="order.id" v-for="order in data">
          <vs-td>
            <p @click="openOrderDetail(order)">{{ order.id }}</p>
          </vs-td>
          <vs-td>
            <p @click="openOrderDetail(order)">
              {{ getRelativeTime(order.created) }}
            </p>
          </vs-td>
          <vs-td>
            <p class="text-center" @click="openOrderDetail(order)">
              {{ numberWithCommas(order.total_resin) }}
            </p>
          </vs-td>
          <vs-td class="rowWeight">
            <div class="vx-row" @click="openOrderDetail(order)">
              <div class="text-centers">
                <vs-td>
                  <p @click="openOrderDetail(order)" class="goldWeight">
                    {{ numberWithCommas(order.total_resin * 13) }}
                  </p>
                </vs-td>
                <vs-td>
                  <p @click="openOrderDetail(order)" class="goldWeight">
                    {{ numberWithCommas(order.total_resin * 15) }}
                  </p>
                </vs-td>
                <vs-td>
                  <p @click="openOrderDetail(order)" class="goldWeight">
                    {{ numberWithCommas(order.total_resin * 18) }}
                  </p>
                </vs-td>
              </div>
            </div>
          </vs-td>

          <vs-td>
            <p @click="openOrderDetail(order)">
              ${{ parseFloat(order.total_price.toFixed(2)) }}
            </p>
          </vs-td>
          <vs-td>
            <div @click="openOrderDetail(order)">
              <vs-chip :color="getOrderStatusColor(order.order_status)">{{
                getOrderStatus(order.order_status)
              }}</vs-chip>
            </div>
          </vs-td>
          <vs-td class="avatars">
            <div v-if="!imageErrorChangePhoto" @click="openOrderDetail(order)">
              <img
                class="avatar"
                @error="imageError"
                :key="item.id"
                v-for="item in order.thumbnails"
                :src="item"
                alt
              />
            </div>
            <div v-else @click="openOrderDetail(order)" class="avatars">
              <img
                class="avatar"
                src="@/assets/images/pages/no_image.png"
                alt
              />
            </div>
          </vs-td>
          <vs-td>
            <vs-button
              class="mr-1 ml-1"
              v-if="getOrderStatus(order.order_status) == $t('Ready')"
              @click="openPlate(order.id)"
              size="medium"
              >{{ $t("Go_To_Plates").toUpperCase() }}</vs-button
            >
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="m-2">
      <vs-pagination
        :max="7"
        :total="totalPage"
        v-model="currentPage"
        @change="handleChangePage(currentPage)"
      />
    </div>
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import moment from "moment";
import vSelect from "vue-select";

import defaultImage from "@/assets/images/pages/no_image.png";

export default {
  data() {
    return {
      searchValue: "",
      searchActive: true,
      imageErrorChangePhoto: false,
      selectedStatus: "",
      statusList: [
        {
          id: "",
          label: this.$t("All"),
        },

        {
          id: "N",
          label: this.$t("New"),
        },
        {
          id: "P",
          label: this.$t("Processing"),
        },
        {
          id: "W",
          label: this.$t("Waiting_Payment"),
        },
        {
          id: "C",
          label: this.$t("Cancelled"),
        },
        {
          id: "R",
          label: this.$t("Ready"),
        },
      ],

      //Pagination
      currentPage: 1,
      totalPage: 1,

      orders: [],
    };
  },

  methods: {
    searchOrder() {
      if (this.searchActive) {
        this.searchActive = false;
        this.getOrders();
      }
    },
    getRelativeTime(value) {
      return moment(String(value)).format("DD/MM/YYYY - HH:mm");
    },
    openPlate(id) {
      var params = {
        order: id,
      };

      this.$router
        .push({
          name: "plates",
          query: params,
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    handleChangePage() {
      this.getOrders();
    },
    imageError(event) {
      event.target.src = defaultImage;
      return true;
    },

    numberWithCommas(x) {
      x = parseFloat(x.toFixed(1));
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    openOrderDetail(order) {
      this.$router
        .push({
          name: "order_detail",
          query: { no: order.id },
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    getOrderItemExtraSize(length) {
      return "+" + (length - 5);
    },
    getCategoryName(id) {
      var categorys = JSON.parse(localStorage.getItem("categorys"));
      categorys.forEach((element) => {
        if (element.id == id) {
          this.categoryTitle = element.title;
          return;
        }
      });
    },
    getOrderStatus(status) {
      if (status == "P") return this.$t("Processing");
      if (status == "R") return this.$t("Ready");
      if (status == "C") return this.$t("Cancelled");
      if (status == "W") return this.$t("Waiting_Payment");
      return this.$t("New");
    },
    getOrderStatusColor(status) {
      if (status == "P") return "warning";
      if (status == "R") return "success";
      if (status == "C") return "danger";
      if (status == "W") return "#389CA3";
      return "primary";
    },
    handleGetOrdersRequest(status, data) {
      this.searchActive = true;
      this.$vs.loading.close();
      if (status == 200) {
        this.totalPage = Math.ceil(data.count / 10);
        this.orders = data.results;
      } else if (status == 0) {
        this.$vs.notify({
          color: "danger",
          title: this.$t("Dental_Message_12"),
          text: this.$t("Dental_Message_11"),
        });
      }
    },
    getOrdersWithStatus() {
      this.currentPage = 1;
      this.getOrders();
    },
    getOrders() {
      var params = {};

      if (this.searchValue != "") {
        params.search = this.searchValue;
      }

      if (this.currentPage != 1) {
        params.page = this.currentPage;
      }

      if (this.selectedStatus.id != "") {
        params.order_status = this.selectedStatus.id;
      }

      this.$router.push({ query: params }).catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });

      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.get(API.BASEURL + API.ORDERS, this.handleGetOrdersRequest, params);
    },
  },
  components: {
    vSelect,
  },
  created: function () {
    if (this.$route.query.page != null && this.$route.query.page != "1") {
      this.currentPage = parseInt(this.$route.query.page);
    }
    if (
      this.$route.query.order_status != null &&
      this.$route.query.order_status != ""
    ) {
      let index = this.statusList.findIndex(
        (e) => e.id == this.$route.query.order_status
      );

      this.selectedStatus = this.statusList[index];
    }
    if (this.$route.query.search != null) {
      this.searchValue = this.$route.query.search;
    }
  },
};
</script>

<style>
.vs-input--icon {
  font-size: 1.5rem;
}
.vs-pagination--mb {
  justify-content: center !important;
}

.v-select .vs__dropdown-toggle .vs__actions .vs__clear {
  display: none;
}

.vs__dropdown-toggle {
  background: white;
}

.vs__dropdown-option--highlight {
  background: #c89e82 !important;
  color: #fff;
}
</style>

<style scoped>
@media (min-width: 601px) {
  .searchAndSort {
    min-width: 220px;
    max-width: 220px;
  }
}
@media (max-width: 601px) {
  .searchAndSort {
    min-width: auto;
    max-width: auto;
  }
}
@media (max-width: 651px) {
  .searchAndSort {
    min-width: 200px;
    max-width: 200px;
  }
}
@media (max-width: 445px) {
  .searchAndSort {
    min-width: -webkit-fill-available;
    max-width: -webkit-fill-available;
  }
}
.avatar {
  border-radius: 50%;
  width: 3rem;
  height: auto;
}
.rowWeight {
  width: 220px;
  background-color: #6262620f;
}
.approxGoldWeight {
  width: 220px;
  justify-content: center !important;
}
.date {
  width: 160px;
  justify-content: center !important;
}
.resinGr {
  width: 80px;
  justify-content: center !important;
}
.goldWeight {
  width: 50px;
}
@media screen and (max-width: 1450px) {
  .avatars {
    display: none !important;
  }
}
</style>
